* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body {
  max-width: 100%;
}

html {
  font-size: clamp(6px, 0.8vw, 14px);
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper>div {
  position: relative;
  z-index: 1
}

@font-face {
  font-family: Sofia;
  src: url("./fonts/Sofia-Pro-Regular.otf") format('opentype');
}

@font-face {
  font-family: Sofia;
  src: url("./fonts/Sofia-Pro-Bold.otf") format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: Sofia;
  src: url("./fonts/Sofia-Pro-Medium.otf") format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Sofia;
  src: url("./fonts/Sofia-Pro-Light.otf") format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Sofia;
  src: url("./fonts/Sofia-Very-Light.otf") format('opentype');
  font-weight: 100;
}


@font-face {
  font-family: Ivy;
  src: url("./fonts/IvyMode-Regular.ttf") format('truetype');
}

@font-face {
  font-family: Ivy;
  src: url("./fonts/IvyMode-Bold.ttf") format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: Ivy;
  src: url("./fonts/IvyMode-SemiBold.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: Ivy;
  src: url("./fonts/IvyMode-Light.ttf") format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: Ivy;
  src: url("./fonts/IvyMode-Thin.ttf") format('truetype');
  font-weight: 100;
}